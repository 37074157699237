@use "sass:color";
$topbarBgLeftColor: #0e406a;
$topbarBgRightColor: #182848;
$topbarSidebarButtonColor: #ffffff;
$topbarIconHoverBgColor: #1c315e;
$topbarMenuHoverBgColor: #0e406a;
$topbarProfileTextColor: #ffffff;
$activeSubMenuItemTextColor: #0e406a;
$sidebarMenuItemActiveRouteTextColor: #0e406a;
$sidebarMenuItemActiveRouteIconColor: #0e406a;

$primaryColor: #0e406a;
$primaryDarkerColor: #355190;
$primaryDarkestColor: #1c315e;
$primaryTextColor: #ffffff;

$containerHeaderBgColor: #050505;
$containerHeaderBorder: 1px solid #050505;
$containerHeaderTextColor: #ffffff;
$containerHeaderIconColor: #d6d6d6;
$containerHeaderIconHoverColor: #ffffff;

$containerHeaderHoverBgColor: #020202;
$containerHeaderHoverBorder: 1px solid #020202;
$containerHeaderHoverTextColor: #ffffff;
$containerHeaderHoverIconColor: #ffffff;

$containerHeaderActiveBgColor: $primaryColor;
$containerHeaderActiveBorder: 1px solid $primaryColor;
$containerHeaderActiveTextColor: $primaryTextColor;
$containerHeaderActiveIconColor: $primaryTextColor;

$containerHeaderActiveHoverBgColor: $primaryDarkerColor;
$containerHeaderActiveHoverHoverBorder: 1px solid $primaryDarkerColor;
$containerHeaderActiveHoverrTextColor: $primaryTextColor;
$containerHeaderActiveHoverIconColor: $primaryTextColor;

$primaryTextColor: #fdfdfd;
$white: $primaryTextColor;
$pureWhite: #ffffff;
$panelHeaderTextColor: #fdfdfd;
$inputErrorBorderColor: #a80000;
$black: #000000;
$success-color: #b7d8b7;
$success-border-color: #56a056;
$warning-color: #fff3cd;
$warning-border-color: #ffd66b;
$error-color: #ff8474;
$error-border-color: #ff3318;
$primary-btn-color: #0e406a;
$form-error-color: $inputErrorBorderColor;
$row-hover-color: #e0e0e0;
$darkest-border-color: #666666;
$light-border-color: #d5d5d5;
$lightest-border-color: #dedede;
$form-input-border-color: #7f7f7f;
$receipt-color: color.adjust($primary-btn-color, $lightness: 30%);
$delivery-color: color.adjust($success-border-color, $lightness: 30%);

$breadcrumb-bgcolor: #efefef;

$menu-sub-item-bgcolor: #1d1d1d;

/** Font size */

$font_size_header1: 3.6em;
$font_size_header2: 2.1em;
$font_size_paragraph: 1.05em;
$font_size_dropdown: 1.3em;
$font_size_action: 1.5em; //Cancel/Save
$font_size_col_header: 0.95em;
$font_size_footer: 0.95em;
/** End Font Size */

/** Font size px */

$font_size_px_paragraph: 12px;
/** End Font Size */

/** Font Weights */

$font_weight_heavy: 700;
/** End font weights */

// $font-family: sans-serif;
$font-size: 12px;
$base-font-size: $font-size;
$link-font-size: 14px;
$button-font-size: 12px;
$menu-font-size: 20px;
$accordion-title-font-size: 18px;

/** Breakpoints / Media Queries */
$xs_min: 2em; //32px
$xs_max: 30em; //480px
$sm_min: 30.063em;
$sm_max: 40em; //640px
$md_min: 40.063em;
$md_max: 64em; //1024px
$lg_min: 64.063em;
$lg_max: 90em; //1440px
$xl_min: 90.063em;
$xs: -"only screen and (min-width: #{$xs_min}) and (max-width: #{$xs_max})";
$sm: -"only screen and (min-width: #{$sm_min}) and (max-width: #{$sm_max})";
$md: -"only screen and (min-width: #{$md_min}) and (max-width: #{$md_max})";
$lg: -"only screen and (min-width: #{$lg_min}) and (max-width: #{$lg_max})";
$xl: -"only screen and (min-width: #{$xl_min})";
$upToSm: -"only screen and (max-width: #{$sm_max})";
$lgAndUp: -"only screen and (min-width: #{$lg_min})";
$upToXs: -"only screen and (max-width: #{$xs_max})";
$xsAndUp: -"only screen and (min-width: #{$xs_min}) ";
$smAndUp: -"only screen and (min-width: #{$sm_min})";
/** End Breakpoints / Media Queries */

$fontFamily: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
$fontSize: 13px;
$textColor: #333333;
$textSecondaryColor: color.adjust($textColor, $lightness: 25%);
$borderRadius: 0px;
$transitionDuration: 0.3s;

//main
$bodyBgColor: #fdfdfd;

//menu
$darkMenuTitleTextColor: #656565;
$darkMenuTitleBorderBottomColor: #383838;
$darkMenuBgColor: #050505;
$darkMenuItemTextColor: #bbbbbb;
$darkMenuItemIconColor: #bbbbbb;
$darkMenuSubmenuBgColor: #1d1d1d;

$lightMenuTitleTextColor: #828282;
$lightMenuTitleBorderBottomColor: #9b9b9b;
$lightMenuBgColor: #ffffff;
$lightMenuItemTextColor: #333333;
$lightMenuItemIconColor: #828282;
$lightMenuSubmenuBgColor: #efefef;

$badgeTextColor: $primaryTextColor;
$badgeBgColor: $primaryColor;

//route bar
$routeBarBgColor: #efefef;
$routeBarMenuItemTextColor: #828282;
$routeBarMenuItemIconColor: #828282;

//slim menu tooltip
$slimMenuTooltipBgColor: #1b1b1b;
$slimMenuTooltipTextColor: #bbbbbb;

//footer
$footerBgColor: #f3f3f3;
$footerTextColor: #1f1f1f;

//right panel
$rightPanelButtonBgColor: #050505;
$rightPanelButtonIconColor: #bbbbbb;
$rightPanelBgColor: #050505;
$rightPanelHeaderBgColor: #323232;
$rightPanelHeaderTextColor: #ffffff;
$rightPanelContentBgColor: #323232;
$rightPanelContentTextColor: #ffffff;
