@use "variables.scss";
@use "mixins.scss";
.button-bar .ui-g-12,
.button-bar .ui-g-6 {
  padding: 0.2em;
}

.button-bar .ui-g-5.ui-lg-5.ui-md-12.ui-sm-12 {
  @include mixins.respondTo(xs) {
    padding: 0;
  }
}

.button-bar label {
  @include mixins.respondTo(xs) {
    margin-bottom: 0px;
    margin-top: 0.5em;
  }
}
.button-bar {
  padding: 5px 10px 5px 10px;
  vertical-align: middle;
  width: 100%;
  @include mixins.respondTo(xs) {
    vertical-align: middle;
    width: 100%;
  }
}

.search-panel {
  height: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.button-bar .ui-g-12 .ui-lg-3 .ui-md-12 {
  padding-top: 1em;
}

.action-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.action-filter-container {
  display: flex;
  justify-content: flex-end;
  min-width: 200px;
  padding-right: 15px;
  > div {
    width: 100%;
  }
}

.action-button-container {
  display: flex;
  justify-content: flex-end;
  div {
    @include mixins.respondTo(md-lg-xl) {
      padding-left: 5px;
      padding-right: 5px;
      button {
        width: 100px !important;
      }
    }
  }
}

.form-control-set {
  @include mixins.respondTo(lg-xl) {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }
  > div:first-child {
    @include mixins.respondTo(lg-xl) {
      &.ui-lg-12,
      &.ui-xl-12 {
        justify-content: flex-start;
        padding-top: 0px;
      }
      min-height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start; // cannot use center because it distorts the label on lookups with multi-select.
      padding-top: 0.95em;
      white-space: nowrap;
    }
    @include mixins.respondTo(md) {
      padding-top: 0.95em;
    }
  }
}
