@use "variables.scss";
@use "mixins";


.mobile,
.ui-g .mobile {
    @include mixins.respondTo(xs-sm) {
        display: inline;
    }
    @include mixins.respondTo(md-lg-xl) {
        display: none;
    }    
}

.mobile-row {

    @include mixins.respondTo(xs) {
        border-top: 1px solid #050505;
        display: table-row!important;
        font-size: 1em;
    }
    @include mixins.respondTo(sm) {
      border-top: 1px solid #050505;
        display: table-row!important;    
    }
    @include mixins.respondTo(md-lg-xl){
        display: none!important;
    }    
}

.mobile-row span {
    @include mixins.respondTo(xs-and-up) {
        padding: 1px!important;
      //  font-weight: bold;
    } 
}

.mobile-row span.ng-star-inserted {
    @include mixins.respondTo(xs-and-up) {
        font-weight: normal;
    }
}

.mobile-row .ui-column-title {
    @include mixins.respondTo(xs-and-up) {
        font-weight: bold!important;
    }
}

.mobile-filter {
    background-color: variables.$primaryTextColor;
    color: variables.$black;
    margin-top: 8px;
    text-align: left;
}

.mobile-filter .ui-g-12 {
    padding-top: 0px!important;
    padding-right: 0px!important;
}

.mobile-filter-label {
    padding-top: 2px;
    margin-bottom: 0px;
    float: left;
    font-size: .8em;
}

.mobile-filter-calendar {
    width: 100%;
}

.mobile-filter-lookup div.ui-inputgroup {
    padding-top: 0px!important;
    padding-left: 0px!important;
}
