@use "variables.scss";
@use "mixins";

body {
  font-size: variables.$font-size;
  font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  min-height: 100%;
}
body .ui-state-disabled,
body .ui-widget:disabled {
  opacity: 0.75 !important;
}
//TODO: Another Primeng6 breaking change.
body.ui-overflow-hidden {
  position: inherit;
}

.header {
  margin-top: -5px;
  padding-top: 2px;
  padding-left: 2px;
  display: block;
}

.ui-card-header {
  padding: 5px;
  display: block;
  font-weight: bold;
}

.header h1 {
  margin: 0.2em;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 36px;
  letter-spacing: 0.1em;
}

.hidden {
  display: "none";
}

.error-message-text {
  color: variables.$error-color !important;
}

.number-column,
.text-right {
  text-align: right !important;
}

/**
 *  The following 2 CSS changes prevent buttons in firefox from being slimmed down
 *  to the size of the icon.
 */
body .ui-button,
body .ui-fluid .ui-inputgroup .ui-button {
  width: auto;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 700;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a {
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 700;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 700;
}

body .ui-button.ui-button-icon-only,
body .ui-fluid .ui-inputgroup .ui-button.ui-button-icon-only {
  width: 2.357em;
}

.context-menu span.ui-menuitem-text {
  font-size: 15px;
}

.context-menu {
  width: 18em;
}

.custom-spinner {
  display: block;
  z-index: 4008;
  text-align: center;
  border-radius: 5px;
}

.custom-lookup-spinner {
  display: block;
  z-index: 4008;
  border-radius: 5px;
}

.no-padding {
  padding: 0px !important;
}

.no-padding-sides {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.no-padding-top-bottom {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.no-padding-bottom {
  padding-bottom: 0px !important;
}

.no-padding-sides-lg {
  @include mixins.respondTo(lg) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.xtra-small-top-padding {
  padding-top: 1em !important;
}

.small-padding {
  padding: 0.2em !important;
}

label,
.label {
  margin-bottom: 0px;
  font-weight: 700;
  display: inline-block;
}

body .ui-button.ui-button-icon-only .ui-button-text {
  padding: 0px !important;
}

.box-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.button-wrapper {
  float: right !important;
}

#icon-container {
  padding-left: 5px;
  > i.fa-exclamation-triangle {
    color: #e91224 !important;
  }
  > i.fa-check {
    color: #34a835 !important;
  }
  > i.fa-hourglass,
  > i.fa-thumbs-o-up {
    color: #007ad9 !important;
  }
}

.ql-mention-list-container {
  width: auto !important;
}

.action-drop-down {
  width: 100% !important;
}

.small-margin {
  margin: 1em !important;
}

.auto-width {
  width: auto !important;
}

.ui-input-group {
  button.width-eighty.ui-button {
    width: 70% !important;
  }

  button.width-twenty.ui-button {
    width: 20% !important;
  }
}

button.ui-button.refresh-button {
  padding: 3.8%;
  margin-left: 10%;
}

.action-drop-down {
  width: 100% !important;
  background: none !important;
}

.p-blockui-document {
  z-index: 100;
}

i.fa {
  // Show pointer cursor on icons
  cursor: pointer;
}

a {
  text-decoration: none;
}
