@use "variables.scss";

.form-control-validation-message {
  color: red !important;
  font-size: 12px;
}

.ui-inputtext.ng-dirty.ng-invalid,
p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext,
p-chips.ng-dirty.ng-invalid > .ui-inputtext,
p-inputmask.ng-dirty.ng-invalid > .ui-inputtext,
p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
p-listbox.ng-dirty.ng-invalid .ui-inputtext,
p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
p-spinner.ng-dirty.ng-invalid > .ui-inputtext,
p-selectbutton.ng-dirty.ng-invalid .ui-button,
p-togglebutton.ng-dirty.ng-invalid .ui-button,
app-calendar.ng-invalid.ng-dirty
  > div
  div.calendar
  > p-calendar
  span.ui-calendar
  input.ui-inputtext {
  border-color: variables.$form-error-color !important;
}

.ui-inputtext.ng-dirty,
p-dropdown.ng-dirty > .ui-dropdown,
p-autocomplete.ng-dirty > .ui-autocomplete > .ui-inputtext,
p-chips.ng-dirty > .ui-inputtext,
p-inputmask.ng-dirty > .ui-inputtext,
p-checkbox.ng-dirty .ui-chkbox-box,
p-radiobutton.ng-dirty .ui-radiobutton-box,
p-inputswitch.ng-dirty .ui-inputswitch,
p-listbox.ng-dirty .ui-inputtext,
p-multiselect.ng-dirty > .ui-multiselect,
p-spinner.ng-dirty > .ui-inputtext,
p-selectbutton.ng-dirty .ui-button,
p-togglebutton.ng-dirty.ui-button {
  border-color: variables.$form-input-border-color !important;
}

.ui-inputtext,
p-dropdown > .ui-dropdown,
p-autocomplete > .ui-autocomplete > .ui-inputtext,
p-chips > .ui-inputtext,
p-inputmask > .ui-inputtext,
p-checkbox .ui-chkbox-box,
p-radiobutton .ui-radiobutton-box,
p-inputswitch .ui-inputswitch,
p-listbox .ui-inputtext,
p-multiselect > .ui-multiselect,
p-spinner > .ui-inputtext,
p-selectbutton .ui-button,
p-togglebutton.ui-button {
  border-color: variables.$form-input-border-color !important;
}

// Removes the red border around p-calendar filter in FireFox (FireFox bug).
#app-calendar div.calendar p-calendar span input {
  box-shadow: none;
}
