/* This file should only import other scss files. */

// Style variables that can be used throughout the application.
@use "app/styles/variables.scss";

// styles related to the button bar used to group controls.
@use "app/styles/button-bar.scss";

// common styles and helper style classes.
@use "app/styles/common.scss";

// styles related to mobile only
@use "app/styles/mobile.scss";

// styles for validation controls/messages
@use "app/styles/validations.scss";

// mixins that can be used throughout the application.
@use "app/styles/mixins.scss";

// common table styles.
@use "app/styles/tables.scss";

// common control styles.
@use "app/styles/controls.scss";

// common layout styles for pages, headers, menu, etc.
@use "app/styles/layout.scss";

// PrimeNG override styles
@use "app/styles/_elements.scss";
