@use "variables.scss";
@use "mixins";



.ui-breadcrumb,
.ui-breadcrumb .ui-widget-header {
    border: none;
}

.ui-breadcrumb .ui-menuitem-text,
.ui-breadcrumb li .ui-menuitem-link {
  @include mixins.respondTo(xs-sm-md) {
        font-size: 1em!important;
    }

    @include mixins.respondTo(lg-xl) {
        font-size: 1em!important;
    }
}

.ui-breadcrumb li .ui-menuitem-link.ui-state-active {
    text-decoration: underline;

    @include mixins.respondTo(xs-sm-md) {
        font-size: 1em!important;
    }

    @include mixins.respondTo(lg-xl) {
        font-size: 1em!important;
    }
}

.ui-breadcrumb,
.ui-breadcrumb .ui-widget-header {
    @include mixins.respondTo(sm) {
        border: none;
    }
}

.ui-breadcrumb-home {
    font-size: 1.3em;
    @include mixins.respondTo(sm) {
        font-size: 1.5em;
    }
}

.ui-splitbutton .ui-button {
    vertical-align: top;
}

.ui-tooltip .ui-tooltip-text {
    white-space: nowrap;
}
