@use "variables.scss";

@mixin transparent-element-with-heavy-font($color) {
    background-color:transparent!important;
    color: $color!important;    
}

@mixin ellipsis-overflow() {
    overflow: hidden;
    text-overflow: ellipsis
}
@mixin full-width() {
    width: 100%!important;
}

@mixin no-padding() {
    padding: 0px!important;
}

@mixin regular-text() {
  font-size: variables.$font-size;
}

@mixin bold-text() {
    font-size: variables.$font-size;
    font-weight: 700;
}

@mixin text-center() {
    text-align: center;
}

@mixin no-border() {
    border: none!important;
}

@mixin font-dark-contrast-heavy() {
    font-weight: 700!important;
}


@mixin table-left-align-light-opacity() {
    text-align: left;
    float: left;
    color: variables.$white;
}

/**
 * Mix in the should be used to handle any media queries for the application.
 */
@mixin respondTo($media) {
    @if $media == xs {
        @media only screen and (min-width: variables.$xs_min) and (max-width: variables.$xs_max) { @content; }
    } @else if $media == sm {
        @media only screen and (min-width: variables.$sm_min) and (max-width: variables.$sm_max) { @content; }
    } @else if $media == md {
        @media only screen and (min-width: variables.$md_min) and (max-width: variables.$md_max) { @content; }
    } @else if $media == lg {
        @media only screen and (min-width: variables.$lg_min) and (max-width: variables.$lg_max) { @content; }
    } @else if $media == xl {
        @media only screen and (min-width: variables.$xl_min) { @content; }
    } @else if $media == xs-and-up {
        @media only screen and (min-width: variables.$xs_min) { @content; }
    } @else if $media == sm-and-up {
        @media only screen and (min-width: variables.$sm_min) { @content; }
    } @else if $media == up-to-sm {
        @media only screen and (max-width: variables.$sm_max) { @content; }
    } @else if $media == up-to-sm {
        @media only screen and (max-width: variables.$md_max) { @content; }
    }  @else if $media == lg-and-up {
        @media only screen and (min-width: variables.$lg_min) { @content; }
    } @else if $media == up-to-xs {
        @media only screen and (max-width: variables.$xs_max) { @content; }
    } @else if $media == xs-sm {
        @media only screen and (min-width: variables.$xs_min) and (max-width: variables.$xs_max),
               only screen and (min-width: variables.$sm_min) and (max-width: variables.$sm_max) { @content; }
    } @else if $media == md-lg-xl {
        @media only screen and (min-width: variables.$md_min) and (max-width: variables.$md_max),
               only screen and (min-width: variables.$lg_min) and (max-width: variables.$lg_max),
               only screen and (min-width: variables.$xl_min) {   @content;    }
    } @else if $media == lg-xl {
        @media only screen and (min-width: variables.$lg_min) and (max-width: variables.$lg_max),
              only screen and (min-width: variables.$xl_min) {  @content;     }
     } @else if $media == xs-sm-md {
        @media only screen and (min-width: variables.$xs_min) and (max-width: variables.$xs_max),
               only screen and (min-width: variables.$sm_min) and (max-width: variables.$sm_max),
               only screen and (min-width: variables.$md_min) and (max-width: variables.$md_max) { @content; }
    }    
}

@mixin background-and-border-change($bg, $border) {
    background-color: $bg !important;
    border: 2px solid $border!important;
}
