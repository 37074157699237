@use "variables.scss";

.nav-panel .ui-card-shadow.ui-card.ui-widget.ui-widget-content.ui-corner-all {
  font-size: 1.4em;
}

.table-of-contents-scroll-panel {
  .ui-scrollpanel-wrapper {
    width: 100%;
    height: 564px;
  }

  .ui-scrollpanel-bar {
    border-radius: 0px;
  }
}

.nav-mobile-dropdown-filter {
  float: right;
}

.nav-page-menu {
  width: 100%;
  height: 100%;
}

.nav-page-menu.ui-panelmenu
  .ui-panelmenu-content
  .ui-panelmenu-header-link:hover {
  border: none !important;
}

.nav-page-menu.ui-panelmenu .ui-panelmenu-panel .ui-panelmenu-header:active,
.nav-page-menu.ui-panelmenu
  .ui-panelmenu-content
  .ui-panelmenu-header-link:active {
  border: none !important;
}

.nav-page-menu.ui-panelmenu .ui-panelmenu-content .ui-menuitem-link:hover,
.nav-page-menu.ui-panelmenu .ui-panelmenu-content .ui-menuitem-link:active {
  border: none !important;
}

.nav-page-menu span.ui-panelmenu-icon {
  float: right;
  font-size: 2.5em;
}

.nav-page-menu.ui-panelmenu
  .ui-panelmenu-panel
  .ui-panelmenu-header
  .ui-menuitem-text {
  font-size: 1.4em;
  font-weight: bold;
}

.nav-page-menu span.ui-menuitem-text {
  font-size: 1.4em;
}

.nav-quick-view-action-button .ui-menuitem,
.nav-quick-view-action-button .ui-menuitem .ui-menuitem-link,
.nav-quick-view-action-button .ui-menuitem .ui-menuitem-text {
  font-size: 1em;
}

.nav-quick-view-action-button .ui-menuitem-text {
  float: none !important;
  white-space: nowrap;
  display: list-item;
  margin-right: 2em;
}

.nav-quick-view-action-button .ui-menu {
  width: auto;
  font-size: 1em;
}

.nonmobile {
  @media only screen and (min-width: variables.$xs_min) and (max-width: variables.$xs_max) {
    display: none;
  }
  @media only screen and (min-width: variables.$sm_min) and (max-width: variables.$sm_max),
    screen and (min-width: variables.$md_min) and (max-width: variables.$md_max),
    screen and (min-width: variables.$lg_min) and (max-width: variables.$lg_max),
    screen and (min-width: variables.$xl_min) {
    display: inline;
  }
}

.nonmobile-toolbar {
  @media only screen and (min-width: variables.$xs_min) and (max-width: variables.$xs_max),
    screen and (min-width: variables.$sm_min) and (max-width: variables.$sm_max) {
    display: none;
  }
  @media only screen and (min-width: variables.$md_min) and (max-width: variables.$md_max),
    screen and (min-width: variables.$lg_min) and (max-width: variables.$lg_max),
    screen and (min-width: variables.$xl_min) {
    display: inline;
  }
}

.nonmobile-row {
  @media only screen and (min-width: variables.$xs_min) and (max-width: variables.$xs_max) {
    display: none;
  }
  @media only screen and (min-width: variables.$sm_min) and (max-width: variables.$sm_max),
    screen and (min-width: variables.$md_min) and (max-width: variables.$md_max),
    screen and (min-width: variables.$lg_min) and (max-width: variables.$lg_max),
    screen and (min-width: variables.$xl_min) {
    display: table-row;
  }
}

span.ui-panelmenu-icon {
  @media only screen and (min-width: variables.$xs_min) and (max-width: variables.$xs_max) {
    float: right;
    font-size: 1.5em;
  }
  @media only screen and (min-width: variables.$sm_min) and (max-width: variables.$sm_max),
    screen and (min-width: variables.$md_min) and (max-width: variables.$md_max),
    screen and (min-width: variables.$lg_min) and (max-width: variables.$lg_max),
    screen and (min-width: variables.$xl_min) {
    float: right;
    font-size: 2.5em;
  }
}

span.ui-menuitem-text {
  @media only screen and (min-width: variables.$xs_min) and (max-width: variables.$xs_max) {
    font-size: 1.1em;
  }
  @media only screen and (min-width: variables.$sm_min) and (max-width: variables.$sm_max),
    screen and (min-width: variables.$md_min) and (max-width: variables.$md_max),
    screen and (min-width: variables.$lg_min) and (max-width: variables.$lg_max),
    screen and (min-width: variables.$xl_min) {
    font-size: 1.5em;
  }
}

.ui-fluid .ui-button-text-icon-left .ui-button-text,
.ui-fluid .ui-button-text-icon-right .ui-button-text {
  padding-left: 2.1em !important;
}

.ui-sidebar-left.ui-sidebar-lg {
  @media only screen and (min-width: variables.$xs_min) and (max-width: variables.$xs_max) {
    font-size: 1em;
    overflow: auto;
  }
  @media only screen and (min-width: variables.$sm_min) and (max-width: variables.$sm_max),
    screen and (min-width: variables.$md_min) and (max-width: variables.$md_max),
    screen and (min-width: variables.$lg_min) and (max-width: variables.$lg_max),
    screen and (min-width: variables.$xl_min) {
    overflow: auto;
  }
}

.nav-table-header {
  @media only screen and (min-width: 40em) and (max-width: 50em) {
    font-size: 0.8em;
    font-weight: bold;
  }
}

.ui-panel,
.grid-container {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.ui-dialog {
  overflow-y: auto !important;
  overflow-x: auto !important;
}

// Menu styles: ripped from old layout-tcenergy.css
.layout-wrapper .layout-sidebar .layout-menu {
  list-style-type: none;
  margin: 0;
  padding: 0 10px;
}

.layout-wrapper .layout-sidebar .layout-menu .layout-menu-tooltip {
  display: none;
}

.layout-wrapper .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #799b3e;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}

.layout-wrapper .layout-sidebar .layout-menu > li.active-menuitem {
  color: #555759;
}

.layout-wrapper .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #555759;
}

.layout-wrapper
  .layout-sidebar
  .layout-menu
  > li.active-menuitem
  > a
  i.layout-menuitem-toggler {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.layout-wrapper .layout-sidebar .layout-menu li a {
  padding: 10px 20px;
  color: #bbbbbb;
  position: relative;
  display: block;
  text-decoration: none;
  border: 1px solid transparent;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.layout-wrapper .layout-sidebar .layout-menu li a .layout-menuitem-toggler {
  position: absolute;
  margin-top: -7px;
  top: 50%;
  right: 16px;
  font-size: 14px;
  left: auto;
  margin-right: 0;
  -moz-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.layout-wrapper .layout-sidebar .layout-menu li a:hover {
  border-color: #799b3e;
}

.layout-wrapper .layout-sidebar .layout-menu li ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #1d1d1d;
}

.layout-wrapper .layout-sidebar .layout-menu li ul li a {
  padding-left: 35px;
}

.layout-wrapper
  .layout-sidebar
  .layout-menu
  li
  ul
  li.active-menuitem
  > a
  i.layout-menuitem-toggler {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

// indent levels for nested menu items
.layout-wrapper .layout-sidebar .layout-menu li ul li ul li a {
  padding-left: 50px;
}
.layout-w rapper .layout-sidebar .layout-menu li ul li ul ul li a {
  padding-left: 65px;
}

.layout-wrapper .layout-sidebar .layout-menu li ul li ul ul ul li a {
  padding-left: 80px;
}

.layout-wrapper .layout-sidebar .layout-menu li ul li ul ul ul ul li a {
  padding-left: 95px;
}
