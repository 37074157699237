@use "variables.scss";
@use "mixins.scss";

@mixin table-cell-mixin() {
  -ms-user-select: none;
  padding: 2px 5px 2px 5px !important;
  text-align: left !important;
  font-size: 12px !important;
  color: variables.$black !important;
}

.ui-table {
  .ui-table-tfoot > tr {
    > td {
      @include table-cell-mixin();
      &.total-row-number-column {
        font-weight: 700 !important;
        text-align: right !important;
      }
    }
  }
  .ui-table-thead > tr {
    > th {
      @include table-cell-mixin();
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
      border-bottom: 1px solid variables.$lightest-border-color;
      &.ui-state-highlight {
        &.rec_group,
        &.del_group {
          background-color: variables.$primary-btn-color !important;
        }
        &:hover {
          color: variables.$black !important;
        }
      }
    }
    > th.rec_group {
      background-color: variables.$receipt-color !important;
    }
    > th.del_group {
      background-color: variables.$delivery-color !important;
    }
  }
  .ui-table-tbody > tr {
    > td {
      @include table-cell-mixin();
      @include mixins.respondTo(md-lg-xl) {
        white-space: nowrap;
      }
      & {
        text-overflow: ellipsis;
        overflow: hidden;
        background: transparent !important;
      }
      a i {
        font-size: 18px;
      }
      &.negative-numbers-red {
        color: variables.$error-border-color !important;
      }
      &.number-column {
        text-align: right !important;
      }
    }
    > td.rec_group {
      background-color: variables.$receipt-color !important;
    }
    > td.del_group {
      background-color: variables.$delivery-color !important;
    }
    &:hover {
      color: variables.$black !important;
      > td.rec_group,
      > td.del_group {
        background-color: variables.$row-hover-color !important;
      }
    }
    &.changed-row {
      font-style: italic;
      td input {
        font-style: italic;
      }
    }
    &.warning-row {
      @include mixins.background-and-border-change(
        variables.$warning-color,
        variables.$warning-border-color
      );
      td a i {
        color: variables.$primary-btn-color !important;
      }
      > td.rec_group,
      > td.del_group {
        @include mixins.background-and-border-change(
          variables.$warning-color,
          variables.$warning-border-color
        );
      }
    }
    &.error-row {
      @include mixins.background-and-border-change(
        variables.$error-color,
        variables.$error-border-color
      );
      td a i {
        color: variables.$white !important;
      }
      > td.rec_group,
      > td.del_group {
        @include mixins.background-and-border-change(
          variables.$error-color,
          variables.$error-border-color
        );
      }
    } //table row styles when highlighted.
    &.ui-state-highlight {
      > td.rec_group {
        background-color: variables.$primary-btn-color !important;
      }
      > td.del_group {
        background-color: variables.$primary-btn-color !important;
      }
      &:hover {
        color: variables.$black !important;
      }
      &.warning-row {
        @include mixins.background-and-border-change(
          variables.$primary-btn-color,
          variables.$warning-border-color
        );
        td,
        td a,
        td a i {
          color: variables.$white !important;
        }
        > td.rec_group,
        > td.del_group {
          @include mixins.background-and-border-change(
            variables.$primary-btn-color,
            variables.$warning-border-color
          );
        }
      }

      &.error-row {
        @include mixins.background-and-border-change(
          variables.$primary-btn-color,
          variables.$error-border-color
        );
        td,
        td a,
        td a i {
          color: variables.$white !important;
        }
        > td.rec_group,
        > td.del_group {
          @include mixins.background-and-border-change(
            variables.$primary-btn-color,
            variables.$error-border-color
          );
        }
      }

      td,
      td a,
      td a i {
        color: variables.$white !important;
      }
    }
  }
}

.ui-table-resizable {
  overflow: visible;
}

.ui-column-title {
  font-weight: bold;
  margin: 0px;
}

p-table > div.ui-table > div.ui-table-wrapper > table {
  @include mixins.respondTo(md) {
    width: auto; // no idea why this is needed for row expanded columns in medium tables.
  }
}

.ui-table thead tr th {
  position: static;
}

.ui-table .ui-table-tbody > tr.ui-contextmenu-selected,
.ui-table .ui-table-tbody > tr:nth-child(even).ui-contextmenu-selected {
  background-color: transparent !important;
}

.ui-table .ui-table-tbody > tr.ui-state-highlight.ui-contextmenu-selected,
.ui-table
  .ui-table-tbody
  > tr.ui-state-highlight:nth-child(even).ui-contextmenu-selected {
  background-color: variables.$primary-btn-color !important;
}

p-contextmenu {
  div {
    width: 22em !important;
  }
  a.ui-menuitem-link {
    text-decoration: none;
  }
  span.ui-menuitem-text {
    font-size: 14px;
  }
}

//override the pi-spin class to be the fa-pulse.
.pi-spin {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

div.ui-paginator-top > span.ui-paginator-pages >  a.ui-paginator-page,
div.ui-paginator-bottom  > span.ui-paginator-pages >  a.ui-paginator-page  {
  width: auto !important;
  padding-left: 2px;
  padding-right: 2px;
}

.ui-paginator-top.ui-paginator,
.ui-paginator-bottom.ui-paginator {
  @include mixins.respondTo(xs-sm) {
    font-size: 1.3em;
  }
}

.ui-paginator-top.ui-paginator .ui-paginator-pages,
.ui-paginator-bottom.ui-paginator .ui-paginator-pages {
  @include mixins.respondTo(xs-sm) {
    display: none;
  }
}

.frozen_border {
  border-right: 3px solid variables.$darkest-border-color !important;
}

.table-caption-date {
  @include mixins.table-left-align-light-opacity();
}

.reflow-table {
  border-top: 1px solid variables.$darkest-border-color;
  tr {
    border-top: 1px solid variables.$darkest-border-color;
  }
}

.ui-table-tfoot .grid-records-total {
  font-weight: bold;
  text-align: right;
}
